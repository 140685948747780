import React from "react";
import { Box, Container, TextField, Typography, Grid, Stepper, Step, StepButton } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { Colors, Gaps, Variables } from "../misc/variables";
import { Particle } from "./components/Login/Particles";
import { AuthHelper } from "./controllers/AuthHelper";
import { useSnackBar } from "./providers/consumers/useSnackBar";
import { useNavigate, useLocation } from "react-router-dom";
import { useDashboardDrawer } from "./providers/consumers/useDashboardDrawer";
import { useRef } from "react";
import Logo from '../assets/images/logo.png';
import GzipHelper from "./controllers/GzipHelper";


const Login = () => {
    
    const [handleForm, setHandleForm] = React.useState({
        Username: "",
        Password: "",
    });
    const [isFormLoading, setFormLoading] = React.useState(false);
    const [changePassword, setChangePassword] = React.useState(false);
    const [cachedUser, setCachedUser] = React.useState({});
    const [isMFA, setIsMFA] = React.useState(false);
    const [mfaImage, setMFAImage] = React.useState("");
    const [accessToken, setAccessToken] = React.useState("");
    const [validationCode, setValidationCode] = React.useState("");
    const [mfaEnabled, setMfaEnabled] = React.useState(false);

    const [confirmationRequired, setConfirmationRequired] = React.useState(false);

    const [regex, setRegex] = React.useState({
        // minimumCharacters: {
        //     regex: /^({8,})$/,
        //     validated: false
        // },
        number: {
            regex: /^(?=.*\d)/,
            validated: false
        },
        uppercase: {
            regex: /[A-Z]/,
            validated: false
        },
        specialCharacter: {
            regex: /(?=.*[@$!%*#?&])/,
            validated: false
        }
    })
    const helper = useRef();

    const { addAlert } = useSnackBar();
    const { pushMobileOpen } = useDashboardDrawer(false);

    const location = useLocation();
    
    let returnUrl = useRef();

    const navigate = useNavigate();

    const handleInput = (key, value) => {
        const form = handleForm;
        form[key] = value;
        setHandleForm({...form});

    }
    
    const handlePasswordReset = async () => {
        if(!regex.number.regex.test(handleForm.Password) || !regex.specialCharacter.regex.test(handleForm.Password) || !regex.uppercase.regex.test(handleForm.Password) || handleForm.Password.length < 8) {
            console.log('provaaaa');
            addAlert({message: 'Devi utilizzare almeno 8 caratteri, una lettera maiuscola, una lettera minuscola, un numero ed un carattere speciale', severity: 'error'})
            return;
        }
        setFormLoading(true);    
        const res = await helper.current.changePassword(cachedUser, handleForm.Password);
        setFormLoading(false);
        if(res.status === 'error')return addAlert({message: 'Errore nel cambiamento della password', severity: 'error'});
        addAlert({message: 'Password resettata con successo! Accedi di nuovo.', severity: 'success'});
        setHandleForm({...handleForm, Password: ''});
        setChangePassword(false);
    }

    const handleLogin = async () => {
        setFormLoading(true);
        const loginRes = await helper.current.login(handleForm);
        
        switch(loginRes.status){
            case 'error':
                setFormLoading(false);
                console.log(loginRes);

                addAlert({message: 'Credenziali non corrette', severity: 'error'});
                if(loginRes.response.message.includes('User is not confirmed')){
                    console.log('CONFERMA RICHIESTA');
                    setCachedUser(loginRes.cachedUser);
                    setConfirmationRequired(true)
                }
                break;
            case 'new_password':
                setChangePassword(true);
                handleInput('Password', '');
                setCachedUser(loginRes.cachedUser);
                addAlert({message: 'è necessario cambiare la password', severity: 'warning'});
                break;
            case 'ok':
                console.log(loginRes);
                addAlert({message: 'Accesso effettuato', severity: 'success'});
                setFormLoading(false);
                setIsMFA(true);
                const base64 = await GzipHelper.unzip(JSON.parse(loginRes.img))
                setMFAImage(base64)
                setAccessToken(loginRes.jwt)
                // window.location.href = returnUrl.current;
                break;
            case 'mfa_required':
                addAlert({message: 'Accesso effettuato. E\' necessaria l\'autenticazione a due fattori.', severity: 'success'});
                setFormLoading(false);
                setIsMFA(true);
                setMFAImage(undefined);
                setAccessToken(loginRes.jwt);
                setCachedUser(loginRes.cachedUser);
                setMfaEnabled(true);
                break;
            default:
                addAlert({message: 'Errore server, rivolgersi allo sviluppatore.', severity: 'error'});
                break;
        }
        
        setFormLoading(false);
    }

    const validateMFA = async () => {
        setFormLoading(true);
        if(mfaEnabled){
            try{
                const status = await AuthHelper.sendMFACode(validationCode, cachedUser);
                window.location.href = `${returnUrl.current}?token=${cachedUser.signInUserSession?.accessToken?.jwtToken}`;
            }catch(ex){
                console.log(ex);
                addAlert({message: 'Codice non corretto. Riprovare.', severity: 'error'});
            }
            setFormLoading(false);
            return;
        }
        const result = await AuthHelper.validateMFA(validationCode, accessToken);
        if(result.Status === 'SUCCESS') {
            addAlert({message: 'Codice corretto', severity: 'success'});
            localStorage.setItem('cruddy-apiKey', accessToken);
            const urlParams = new URLSearchParams(location.search);
            returnUrl.current = urlParams.get('return-url');
            window.location.href = `${returnUrl.current}?token=${accessToken}`;
        }else{
            addAlert({message: 'Codice non corretto. Riprovare.', severity: 'error'});
        }
        setFormLoading(false);
    }

    const invalidateCache = () => localStorage.clear();

    React.useEffect(() => {
        pushMobileOpen(false);

        helper.current = AuthHelper.getInstance();

        const urlParams = new URLSearchParams(location.search);
        returnUrl.current = urlParams.get('return-url');
        const invalidate = urlParams.get('invalidate');

        if(invalidate) invalidateCache();

        if(localStorage.getItem('cruddy-user')){
            window.location.href = `${returnUrl.current}?token=${localStorage.getItem('cruddy-apiKey')}`;
        }
        // localStorage.getItem('cruddy-user') && navigate('dashboard');
    }, [navigate, pushMobileOpen, location.search]);

    const validateCode = async() => {
        try{
            const res = await AuthHelper.confirmUser(cachedUser, validationCode);
            console.log(res);
            window.location.reload();
        }catch(ex){
            addAlert({
                message: 'Codice di verifica non corretto. Riprova',
                severity: 'error'
            });
        }
    }
    return (
    <React.Fragment>
        <Grid container sx={{height: '100vh'}}>
            <Grid item xs={6} sx={{maxHeight: '100%', 
                background: 'linear-gradient(to right, #2EAAFA, #1F2F98)', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center',
                position: 'relative'}}>
                <img src={Logo} alt="" style={{width: 350}}/>
                <Particle />
            </Grid>
            <Grid item xs={6} sx={{display: 'flex', alignItems: 'center', boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;"}}>
                <Container maxWidth="md">
                    <Box sx={{ bgcolor: Colors.secondaryColor, display: 'flex', flexDirection: 'column', marginTop: Gaps.defaultGap, borderRadius: Variables.defaultBorderRadius, padding: Gaps.defaultGap}}>
                        <Container maxWidth="sm" sx={{paddingBottom: Gaps.defaultGap}}>
                            <Stepper activeStep={isMFA ? 1 : 0}>
                                <Step completed={isMFA}>
                                    <StepButton disabled>Inserisci le credenziali</StepButton>
                                </Step>
                                <Step completed={false}>
                                    <StepButton disabled>Autenticazione a 2 fattori</StepButton>
                                </Step>
                            </Stepper>
                        </Container>
                        {!isMFA && !confirmationRequired && <><Typography variant="h3" color="#fff">{changePassword ? 'Cambia password' : 'Accedi'}</Typography>
                        <Container maxWidth="sm">
                            <div className="form-controller">
                                <TextField InputLabelProps={{
            shrink: true,
          }} type="email" fullWidth variant="outlined" label="E-mail" value={handleForm.Username} onChange={(e) => handleInput('Username', e.currentTarget.value)} />
                            </div>
                            <div className="form-controller">
                                <TextField InputLabelProps={{
            shrink: true,
          }} type="password" fullWidth variant="outlined" label="Password" value={handleForm.Password} onChange={(e) => handleInput('Password', e.currentTarget.value)} />
                            {changePassword && <ul style={{listStyle: 'none', color: '#fff', textAlign: 'left', paddingLeft: 0}}>
                                <li style={{color: regex.number.regex.test(handleForm.Password) ? '#00ff00' : '#ff0000'}}>{regex.number.regex.test(handleForm.Password) ? '✅' : '🚫'} Deve contenere almeno un numero</li>
                                <li style={{color: regex.uppercase.regex.test(handleForm.Password) ? '#00ff00' : '#ff0000'}}>{regex.uppercase.regex.test(handleForm.Password) ? '✅' : '🚫'} Deve contenere almeno una lettera maiuscola</li>
                                <li style={{color: regex.specialCharacter.regex.test(handleForm.Password) ? '#00ff00' : '#ff0000'}}>{regex.specialCharacter.regex.test(handleForm.Password) ? '✅' : '🚫'} Deve contenere almeno un carattere speciale</li>
                                <li style={{color: handleForm.Password.length >= 8 ? '#00ff00' : '#ff0000'}}>{handleForm.Password.length >= 8 ? '✅' : '🚫'} Deve contenere almeno un carattere speciale</li>
                            </ul>}
                            </div>
                            <Box sx={{paddingTop: Gaps.defaultGap, paddingBottom: Gaps.defaultGap}}>
                                <LoadingButton 
                                    variant="contained" 
                                    fullWidth 
                                    onClick={changePassword ? handlePasswordReset : handleLogin}
                                    loading={isFormLoading}>{changePassword ? 'Invia' : 'Accedi'}</LoadingButton>
                            </Box>
                        </Container></>}
                        {isMFA && (
                        <Container maxWidth="sm">
                            <Typography variant="h6" color="#fff">Inserisci il codice di autenticazione a due fattori</Typography>
                            <div style={{marginTop: Gaps.defaultGap, marginBottom: Gaps.defaultGap, display: 'flex', justifyContent: 'center'}}>
                                {mfaImage && <img src={mfaImage} style={{width: 120, height: 120, marginRight: Gaps.defaultGap}} alt=""/> }
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: 100}}>
                                    <TextField label="Inserisci Codice" onChange={(e) => setValidationCode(e.target.value)} value={validationCode} fullWidth={!mfaImage}></TextField>
                                    <LoadingButton variant="contained" onClick={() => validateMFA()} loading={isFormLoading}><strong>Valida Codice</strong></LoadingButton>
                                </div>
                            </div>
                        </Container>
                        )}

                        {confirmationRequired && <Container maxWidth="sm">
                            <Typography variant="h6" color="#fff">Inserisci il codice di conferma ricevuto via mail</Typography>
                            <div style={{marginTop: Gaps.defaultGap, marginBottom: Gaps.defaultGap, display: 'flex', justifyContent: 'center'}}>
                                {mfaImage && <img src={mfaImage} style={{width: 120, height: 120, marginRight: Gaps.defaultGap}} alt=""/> }
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: 100}}>
                                    <TextField label="Inserisci Codice" onChange={(e) => setValidationCode(e.target.value)} value={validationCode}></TextField>
                                    <LoadingButton variant="contained" onClick={() => validateCode()} loading={isFormLoading}><strong>Valida Codice</strong></LoadingButton>
                                </div>
                            </div>
                        </Container>}
                    </Box>
                </Container>
            </Grid>
        </Grid>
    </React.Fragment>
    )
}

export { Login };