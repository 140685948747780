import { createBrowserRouter } from "react-router-dom";
import { Login } from "../views/Login";

const Router = createBrowserRouter([
    {
        path: '/',
        element: <Login />
    }
])

export { Router };