const ServerConfig = {
    AWS: {
        secret: "/vgg2ahjMYs0T/C+MSm2D50w/Y1pC4LoGZyPswDo",
        accessKey: "AKIA2VBRBN7HOVSX6HX3",
        Cognito: {
            Region: "eu-west-1",
            UserPoolId: "eu-west-1_vaZzKUj4G",
            ClientId: "3ins7cv4qnsfpjep2akfdnd20v",
        }
    }
}

export { ServerConfig };